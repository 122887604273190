<template>
  <main class="container_order px-3 py-4">
    <div class="row no-gutters">
      <div class="col-sm-12 price_block px-2 align-center">
        <div class="price_block_left">
          <h5 class="title_price ma-0">PRODUCT REGISTER</h5>
          <!-- {{ this.$t('PRICE_TITLE') }} -->
        </div>
        <!-- <div class="price_gr">
          <span class="mr-3">{{ this.$t('GROUPS') }}</span>
          <div class="dropdown">
            <v-select
              v-model="selectedGroup"
              :items="this.$t('DROPDOWN.items')"
              hide-details
              :placeholder="this.$t('SELE_CTED_GROUP')"
              dense
              solo
              flat
            ></v-select>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row no-gutters mb-16">
      <div class="col-sm-3 px-2 py-0">
        <aside class="nav-price toggle">
          <div class="range_price">
            <!-- <div class="range_p">
              <h5 class="range_t"><a href="#">Price</a></h5>
              <v-range-slider
                color="#84C897"
                v-model="value"
                track-color="'#E8E8E8'"
                thumb-label="always"
                class="px-5"
                hide-details
                :min="0"
                :max="200"
              ></v-range-slider>
            </div> -->
            <div class="mb-5 category_item_main">
              <v-expansion-panels
                accordion
                multiple
                flat
                tile
                v-model="rightPanels"
              >
                <v-expansion-panel :value="true">
                  <v-expansion-panel-header
                    @click="toggleChildren"
                    class="pl-5 pr-4 pt-0 pb-0"
                  >
                    <template @click="toggleChildren" v-slot:actions>
                      <v-icon color="rgba(0, 0, 0, 0.87)" class="p_vector">
                        {{ isOpened ? 'mdi-minus' : 'mdi-plus' }}
                      </v-icon>
                    </template>
                    <span class="category_title">Category</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- <category-item
                      v-for="category in getProductCategories"
                      :key="category.id"
                      :category="category"
                      :fetchReyestrItems="fetchReyestrItems"
                    ></category-item> -->
                    <!-- return-object -->
                    <v-treeview
                      dense
                      open-all
                      :items="getProductCategories"
                      item-key="id"
                      open-on-click
                      item-children="childrens"
                      item-text=""
                    >
                      <template v-slot:prepend="{ item, open }">
                        <div @click="handleCategoryClick">
                          {{ item.name }}
                        </div>
                      </template>
                    </v-treeview>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </aside>
      </div>
      <div class="col-sm-9 right_b px-2 py-0">
        <ProductListItem
          v-for="item in filteredShoes"
          :item="item"
          :key="item.slug"
          @click.native="
            $router.push({ name: 'product item', params: { id: item.slug } })
          "
        />
      </div>
      <nav class="mb-16" aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li class="page-item disabled">
            <a
              class="page-link"
              href="#"
              @click.prevent="goToPage(currentPage - 1)"
              tabindex="-1"
              >Previous</a
            >
          </li>
          <li
            v-for="(page, index) in Array.from(
              {
                length: this.getTotalPages(
                  reyestrItems?.to,
                  reyestrItems?.per_page
                ),
              },
              (_, index) => index + 1
            )"
            :key="index"
            class="page-item"
            ><a class="page-link" @click.prevent="goToPage(page)" href="#">{{
              page
            }}</a></li
          >
          <li class="page-item">
            <a class="page-link" @click.prevent="goToPage(2)" href="#">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ProductListItem from '../content/ProductListItem.vue'
// import CategoryItem from '../content/ProductCategoryItem.vue'
const { mapState, mapActions } = createNamespacedHelpers('reyestr')

export default {
  name: 'AdminReyestrProductList',
  components: {
    ProductListItem,
    // CategoryItem,
  },
  data() {
    return {
      selectedGroup: '',
      isOpen: false,
      filters: this.$t('FILTERS'),
      activeLang: this.$store.state.language,
      isOpened: false,
      value: [0, 200],
      rightPanels: [0],
      selectedCategories: [],
      currentPage: 1,
      totalPages: 3,
      itemsPerPage: 10,
    }
  },
  computed: {
    ...mapState(['reyestrItems', 'productCategory']),
    getProductCategories() {
      return Object.values(this.productCategory)
        .map((item) => [
          {
            ...item,
            isOpen: false,
          },  
        ])
        .flat()
    },
    filteredShoes() {
      const result = this.reyestrItems?.data?.filter(
        (s) =>
          this.selectedFilters.indexOf(s.group) > -1 ||
          this.selectedFilters.indexOf(s.productionTechnique) > -1 ||
          this.selectedFilters.indexOf(s.model) > -1
      )
      return this.selectedFilters.length >= 1 ? result : this.reyestrItems?.data
    },
    filteredByPrice() {
      const result = this.filteredShoes.filter(
        (s) => this.value[0] < s.newPrice && s.newPrice < this.value[1]
      )
      return result
    },
    selectedFilters() {
      const filters = {
        ...this.$t('FILTERS')[this.$t('FILTER_KEYS.groups')],
        ...this.$t('FILTERS')[this.$t('FILTER_KEYS.production_technique')],
        ...this.$t('FILTERS')[this.$t('FILTER_KEYS.model')],
      }
      const selectedFilters = Object.keys(filters).filter((key) => filters[key])
      return selectedFilters
    },
  },
  watch: {
    selectedGroup(val) {
      this.resetFiltersType(
        true,
        this.$t('FILTERS')[this.$t('FILTER_KEYS.groups')]
      )
      if (val !== this.$t('SELE_CTED_GROUP')) {
        this.filters[this.$t('FILTER_KEYS.groups')][val.toLowerCase()] = true
      }
    },
  },
  methods: {
    ...mapActions([
      'fetchReyestrItems',
      'setSelectedGroup',
      'fetchProductCategories',
    ]),
    resetFiltersType(toReset, filtersType) {
      if (toReset) {
        Object.keys(filtersType).forEach((key) => {
          filtersType[key] = false
        })
      }
    },
    handleCategoryClick(item) {
      this.fetchReyestrItems(item.target.textContent)
    },
    toggleChildren() {
      this.isOpened = !this.isOpened
    },
    goToPage(page) {
      if (
        page >= 1 &&
        page <=
          this.getTotalPages(this.reyestrItems?.to, this.reyestrItems?.per_page)
      ) {
        this.currentPage = page
        this.fetchReyestrItems('', this.currentPage) // Выполняем запрос на сервер при смене страницы
      }
    },
    getTotalPages(total, perPage) {
      if (total <= perPage) {
        return 1
      }
      return Math.floor(total / perPage)
    },
  },
  async created() {
    await this.fetchReyestrItems()
    await this.fetchProductCategories()
    await this.toggleChildren()
  },
}
</script>

<style scoped lang="scss">
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;
@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

.price_block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;

  .price_block_left {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    .title_price {
      @include ftz(1.5rem, 600, #31343c);
      text-transform: capitalize;
      letter-spacing: 1px;

      @media (max-width: 540px) {
        @include ftz(1rem, 600, #31343c);
      }
    }
  }

  .price_gr {
    @include ftz(0.875rem, 500, #666666);
    text-transform: capitalize;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  button:focus:not(:focus-visible) {
    background: #ffffff;
    @include ftz(0.875rem, 500, #666666);
    border: 1px solid #ffffff;
  }
}

.mb-5.category_item_main .v-expansion-panel-content .v-expansion-panel-content__wrap > div > div button {
    display: none;
}

.mb-5.category_item_main .v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0 10px!important;
}

.category_item_main .v-treeview.v-treeview--dense.theme--light {
    padding-bottom: 10px;
}

.category_item_main .mdi-set, .category_item_main .mdi:before {
    color: #84C897;
}


.mdi-set, .mdi:before {
    color: #cfebcb;
}
span.category_title {
    font-weight: 500;
}
.n_close {
  display: none;
}

.category_title {
  text-transform: capitalize;
  padding-left: 3px;
}
.v-treeview-node {
  cursor: pointer;
}

.p_vector {
  font-size: 18px;
}

.opt_ttl .p_r_sum_t {
    font-size: 12px!important
}

.opt_desc p, .opt_desc p * {
    font-size: 13px!important;
    line-height: 14px!important
}

.opt_desc h2, .opt_desc p h2 {
    font-size: 14px!important
}

.range_price {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .range_p {
    height: 110px;
    background: #ffffff;
    border-radius: 1px;

    .range_t {
      padding-top: 24px;
      padding-left: 24px;

      a {
        @include ftz(0.875rem, 700, #666666);
        text-transform: capitalize;
      }
    }

    .range_line {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      overflow: hidden;
      margin-right: 1.5rem;

      .line_group_top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 0.5rem;
        width: 100%;
        .r_line {
          background: #84c897;
          width: 40%;
          height: 3px;
          border-radius: 1px;
          margin-left: 1rem !important;
          margin-top: 1rem;
        }
        .r_line_max {
          width: 60%;
          height: 3px;
          background: #e8e8e8;
          border-radius: 1px;
        }
      }
      .line_group_bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        width: 45%;
        margin-left: 1.3rem;
        position: relative;
        .group_bottom_left {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-content: flex-start;
          align-items: center;
          .line_left {
            background: #84c897;
            width: 10px;
            height: 3px;
            border-radius: 1px;
            transform: rotate(90deg);
            position: absolute;
            top: -3px;
          }
        }

        .group_bottom_right {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-content: flex-start;
          align-items: center;
          .line_right {
            background: #84c897;
            width: 10px;
            height: 3px;
            border-radius: 1px;
            transform: rotate(90deg);
            position: absolute;
            top: -3px;
          }
        }
      }
    }
  }

  .category_wrap_list {
    list-style-type: none;
    padding-left: 0;
  }

  .price_group {
    background: #ffffff;
    margin: 10px 0;
    padding-left: 24px;
    padding-bottom: 5px;
    .p_g_ttl {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-right: 1.5rem;

      .r_title {
        padding-top: 7px;
        @include ftz(0.875rem, 700, #666666);
        text-transform: capitalize;
      }

      .b_vector {
        display: inline-block;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        font-size: 9px;
      }
    }

    .price_t {
      text-transform: uppercase;
    }

    .g-item {
      display: flex;
      align-items: center;
      .form-check-input {
        cursor: pointer;
        position: relative;
      }
      .form-check-input:checked {
        background-color: $green_c;
        border-color: $green_c;
      }
      @include ftz(0.75rem, 500, #666666);
      text-transform: capitalize;
    }
  }
}

.right_b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: fit-content;
  @media (max-width: 896px) {
    width: 100%;
  }

  .price_right {
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 0.5rem;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    cursor: pointer;

    @media (max-width: 541px) {
      overflow: scroll;
    }
    @media (max-width: 481px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .p_img {
      img {
        width: 100%;
      }
    }

    .p_right_t {
      @include ftz(1rem, 500, #423f3d);
      text-transform: capitalize;
    }

    .p_r_sum {
      @include ftz(0.875rem, 500, #423f3d);
      text-transform: capitalize;

      .p_r_sum_t {
        @include ftz(0.625rem, 500, #423f3d);
        text-transform: uppercase;
        width: 53px;
        height: 14px;
        background: #e8d36a;
        border-radius: 2px;
        margin-left: 8px;
        padding: 3px 6px;
      }
    }

    .about_p {
      @include ftz(0.75rem, 400, #666666);
      margin-bottom: 0.5rem;
      padding-top: 12px;
    }

    .btn_wholesale {
      .o_wholesale,
      .o_by_shop {
        width: 88%;
        height: 3rem;
        background: #cfebcc;
        border-radius: 2px;
        margin-top: 24px;
        margin-bottom: 7px;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        @media (max-width: 481px) {
          width: 100%;
        }
      }

      .wholesale,
      .by_shop {
        @include ftz(0.625rem, 500, #666666);
        text-transform: uppercase;
        letter-spacing: 0.13em;
        padding-top: 5px;

        @media (max-width: 668px) {
          @include ftz(0.5rem, 500, #666666);
        }
      }

      .customisation,
      .for_order {
        @include ftz(0.625rem, 500, #9cc298);
        text-transform: uppercase;
        letter-spacing: 0.13em;
        text-align: center;

        @media (max-width: 668px) {
          @include ftz(0.5rem, 500, #666666);
        }
      }

      .o_by_shop {
        border: 1px solid #c5dfc2;
        background: transparent;
        margin: 0;
      }
    }
  }
}
</style>
<style lang="scss">
з .container_order {
  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: #666666;
  }
  .range_price {
    .v-slider__thumb {
      border-radius: unset;
      width: 3px;
      left: -1.5px;
      &:before {
        content: none;
      }
    }
    .v-slider__thumb-label {
      height: 10px !important;
      width: 10px !important;
      background-color: unset !important;
      color: black;
      border-color: unset !important;
    }
  }
  .price_group {
    .v-expansion-panel-header {
      min-height: unset;
    }
    .v-expansion-panel-content__wrap {
      padding-left: 0;
    }
  }
}
</style>
